.flex-grow-0 { flex-grow: 0 !important; }
.flex-grow-1 { flex-grow: 1 !important; }

// General alignment

.align-baseline    { vertical-align: baseline !important; } // Browser default
.align-top         { vertical-align: top !important; }
.align-middle      { vertical-align: middle !important; }
.align-bottom      { vertical-align: bottom !important; }
.align-text-bottom { vertical-align: text-bottom !important; }
.align-text-top    { vertical-align: text-top !important; }

// stylelint-disable declaration-no-important

@each $value in $overflows {
  .overflow-#{$value} { overflow: $value !important; }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $value in $displays {
      .d#{$infix}-#{$value} { display: $value !important; }
    }
  }
}

@media print {
  @each $value in $displays {
    .d-print-#{$value} { display: $value !important; }
  }
}

