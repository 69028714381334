@use "variables";

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          500 !default;

$spacing-size: 8;

$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-md: 960px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1920px;

$grid-breakpoints: (
        xs: 0,
        sm: 600px,
        md: 960px,
        lg: 1280px,
        xl: 1920px
) !default;

// Utilities

$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex !default;
$overflows: auto, hidden !default;
$positions: static, relative, absolute, fixed, sticky !default;


// Spacing
$spacer: 8px !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
                (
                        0: 0,
                        1: ($spacer),
                        2: ($spacer * 2),
                        3: $spacer * 3,
                        4: ($spacer * 4),
                        5: ($spacer * 5)
                ),
                $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
                (
                        25: 25%,
                        50: 50%,
                        75: 75%,
                        100: 100%,
                        auto: auto
                ),
                $sizes
);

$overflows: auto, hidden !default;
