
@function padding($inc) {
  @return ($inc * $spacing-size) + px
}

@mixin set-padding($top, $right, $bottom, $left) {
  @if ($top and $right and $bottom and $left) {
    padding: (($top * $spacing-size) + px) (($right * $spacing-size) + px) (($bottom * $spacing-size) + px) (($left * $spacing-size) + px);
  } @else if ($top and $right) {
    padding: (($top * $spacing-size) + px) (($right * $spacing-size) + px);
  } @else if ($top) {
    padding: (($top * $spacing-size) + px);
  }
}

@mixin set-margin($top, $right, $bottom, $left) {
  @if ($top and $right and $bottom and $left) {
    margin: (($top * $spacing-size) + px) (($right * $spacing-size) + px) (($bottom * $spacing-size) + px) (($left * $spacing-size) + px);
  } @else if ($top and $right) {
    margin: (($top * $spacing-size) + px) (($right * $spacing-size) + px);
  } @else if ($top) {
    margin: (($top * $spacing-size) + px);
  }
}
