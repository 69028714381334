// Alignment
.text-justify  { text-align: justify !important; }
.text-wrap     { white-space: normal !important; }
.text-nowrap   { white-space: nowrap !important; }
.text-truncate { @include text-truncate(); }

// Responsive alignment

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .text#{$infix}-left   { text-align: left !important; }
    .text#{$infix}-right  { text-align: right !important; }
    .text#{$infix}-center { text-align: center !important; }
  }
}

// Transformation

.text-lowercase  { text-transform: lowercase !important; }
.text-uppercase  { text-transform: uppercase !important; }
.text-capitalize { text-transform: capitalize !important; }

// Weight and italics

.font-weight-light   { font-weight: $font-weight-light !important; }
.font-weight-lighter { font-weight: $font-weight-lighter !important; }
.font-weight-normal  { font-weight: $font-weight-normal !important; }
.font-weight-bold    { font-weight: $font-weight-bold !important; }
.font-weight-bolder  { font-weight: $font-weight-bolder !important; }
.font-italic         { font-style: italic !important; }

.text-decoration-none { text-decoration: none !important; }

.text-break {
  word-break: break-word !important; // IE & < Edge 18
  overflow-wrap: break-word !important;
}

// Contextual colors

.text-white { color: #ffffff !important; }
.text-muted { color: rgba(0, 0, 0, 0.54) !important; }
.text-red { color: red !important; }
.text-warning { color: #ff4a00 !important; }

.text-black-50 { color: rgba(#000000, .5) !important; }
.text-white-50 { color: rgba(#fff, .5) !important; }

// Reset

.text-reset { color: inherit !important; }


.size12 {
  font-size: 12px !important;
}
.size13 {
  font-size: 13px !important;
}
.size14 {
  font-size: 14px !important;
}
.size16 {
  font-size: 16px !important;
}
.size18 {
  font-size: 18px !important;
}
.size20 {
  font-size: 20px !important;
}
.size30 {
  font-size: 30px !important;
}
.size40 {
  font-size: 40px !important;
}
